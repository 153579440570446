let $buttons = $('.forms .type-select .square-button');

$buttons.click(function(e) {
    e.preventDefault();
    $buttons.removeClass('selected');
    $(this).addClass('selected');
    if($(this).attr('href') == '#privat') {
        $('#form-bedrift').hide();
        $('#form-privat').show();
    }
    else {
        $('#form-privat').hide();
        $('#form-bedrift').show();
    }
});