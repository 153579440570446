let $mapLink = $('.hero').find('.map-link');

if ($mapLink.length > 0) {
    $mapLink.hover(function() {
        $(this).addClass('hover');
    }, function(){
        $(this).removeClass('hover');
    });
    $mapLink.click(function() {
        let $href = $mapLink.find('a').attr('href');
        window.location = $href;
    });
}

$('.hero').find('.continue-indicator a').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var $parent = $this.parents('.hero');
    var $next = $parent.next('*');

    if ($next.length > 0) {
        $('html, body').animate({
            scrollTop: $next.offset().top - 100
        }, 400);
    }
});