const $menuIcon = $('header .menu-wrapper #nav-icon');
const $header = $('header');
const $body = $('body');
const $navMenu = $header.find('.nav-menu');

let $mainColor = $header.attr('data-color');

if ($menuIcon.length > 0) {
    $menuIcon.click(function () {
        if ($header.hasClass('show-menu')) {
            hideMenu();
        }
        else {
            $header.css({
                'background-color': $mainColor
            });
            $body.addClass('no-scroll');
            $header.addClass('show-menu');
            $menuIcon.addClass('open');
            $navMenu.addClass('open');
        }
    });

    function hideMenu() {
        if (!$header.hasClass('sticky')) {
            $header.css({
                'background-color': 'transparent'
            });
        }
        $body.removeClass('no-scroll');
        $menuIcon.removeClass('open');
        $navMenu.removeClass('open');
        $header.removeClass('show-menu');
    }
}
if ($mainColor == '#fff') {
    $('header .menu-wrapper .nav-menu .menu-content ul li a:not(.square-button)').css({
        'color': '#000'
    });
}
