import Glide from "@glidejs/glide";

function initImagesSlider(id) {
    var imagesGlide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        gap: 0,
        autoplay: 8000,
        animationDuration: 4000,
        animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
    });

    var images = document.getElementById(id);
    if (images && images.querySelector('.glide__slides').children.length > 1) {
        imagesGlide.mount();
        var glideNavigation = images.querySelector('.glide-navigation');
        glideNavigation.querySelector('.active div').style.backgroundColor = glideNavigation.getAttribute('data-color');

        if (glideNavigation) {
            imagesGlide.on('run', function (e) {
                var items = glideNavigation.querySelectorAll('a');
                items.forEach(function (item) {
                    item.classList.remove('active');
                    item.querySelector('div').style.backgroundColor = 'transparent';
                });

                items[imagesGlide.index].classList.add('active');
                items[imagesGlide.index].querySelector('div').style.backgroundColor = glideNavigation.getAttribute('data-color');

            });

            glideNavigation.querySelectorAll('a').forEach(function (link) {
                link.addEventListener('click', function (e) {
                    e.preventDefault();
                    imagesGlide.go('=' + this.getAttribute('data-id'));
                });
            });
        }
    }
}

window.initImagesSlider = initImagesSlider;